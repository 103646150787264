.App {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-size: calc(5px + 2vmin);
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  /* background-color: #282c34; */
  /* min-height: 30vh; */
  /* background: url('../public/xebia-logo.png') no-repeat center top fixed; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* align-content: center; */
  /* justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  padding: 2vh 3vw;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  min-width: 50vw;
}

.Leaderboard-entry {
  display: flex;
  justify-content: baseline;
  padding: 1vmin;
}

.Leaderboard-entry :nth-child(1) {
  min-width: 7vmin;
}

.Leaderboard-entry :nth-child(3) {
  margin-left: auto;
}

/* .App-content > :nth-child(even) {
  background-color: #eee;
} */

